import { AspectRatio, Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
// import './Styles.css';

function HomeSection1() {
  return (
    <ChakraProvider>
      <Flex
        // className="observer1"
        // data-id="3"
        // id="homeSection1"
        bgColor="none"
        width="100vw"
        // paddingTop={['12', '16', '20', '20']}
        paddingBottom={['12', '16', '20', '20']}
        justifyContent="center"
      >
        <Flex
          paddingX={['2', '2', '5']}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Flex
            width="100%"
            maxWidth="1280px"
            // paddingY={['4', '12', '16', '16']}
            justifyContent="center"
            grow="1"
            // paddingX={['2', '2', '5']}
          >
            <AspectRatio
              ratio={16 / 9}
              width="100%"
              maxW="1280px"
              marginBottom="80px"
              marginTop="40px"
            >
              <iframe
                // width="560"
                // height="315"
                src="https://www.youtube-nocookie.com/embed/YxwTu1CYaZU?si=oouXupFcl3LYlfEw&rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </AspectRatio>
          </Flex>
          <Flex
            direction="column"
            // padding={['0 0px', '0 30px', '30px 30px', '30px 30px', '30px 30px']}
            paddingX="5"
            maxWidth={['100%', '90%', '80%', '60%', '60%']}
            width="96%"
            textAlign="center"
          >
            <Box
              className="cubespinner"
              height={['80px', '80px', '100px', '100px', '100px']}
            >
              <Flex
                className="face1"
                lineHeight={['1.47', '1.33', '1.2', '1.2']}
                fontSize={['26px', '30px', '40px', '44px', '44px']}
                fontWeight={['500']}
                bgGradient="linear-gradient(0deg, var(--slate-400), var(--emerald-300))"
                bgClip="text"
                textAlign="center"
                justifyContent="center"
                textShadow="0 1px #00000029"
              >
                Hassle-free Experience
              </Flex>
              <Flex
                className="face2"
                lineHeight={['1.47', '1.33', '1.2', '1.2']}
                fontSize={['30px', '30px', '40px', '44px', '44px']}
                fontWeight={['500']}
                bgGradient="linear-gradient(0deg, var(--slate-400), var(--sky-300))"
                bgClip="text"
                textAlign="center"
                justifyContent="center"
                textShadow="0 1px #00000029"
              >
                Better Solutions
              </Flex>
              <Flex
                className="face3"
                lineHeight={['1.47', '1.33', '1.2', '1.2']}
                fontSize={['30px', '30px', '40px', '44px', '44px']}
                fontWeight={['500']}
                bgGradient="linear-gradient(0deg, var(--slate-400), var(--orange-300))"
                bgClip="text"
                textAlign="center"
                justifyContent="center"
                textShadow="0 1px #00000029"
              >
                Faster Results
              </Flex>
            </Box>
            {/* <Text
              className="cubespinner face1"
              lineHeight={['1.47', '1.33', '1.2', '1.2']}
              fontSize={['40px', '40px', '50px', '60px', '60px']}
              fontWeight={['600']}
            >
              Enabling Innovation
            </Text>
            <Text
              className="cubespinner face2"
              lineHeight={['1.47', '1.33', '1.2', '1.2']}
              fontSize={['40px', '40px', '50px', '60px', '60px']}
              fontWeight={['600']}
            >
              Better Solutions
            </Text>
            <Text
              className="cubespinner face3"
              lineHeight={['1.47', '1.33', '1.2', '1.2']}
              fontSize={['40px', '40px', '50px', '60px', '60px']}
              fontWeight={['600']}
            >
              Faster Results
            </Text> */}
          </Flex>
          <Box
            className="smallDivider"
            width="calc(min(300px, 90%))"
            margin="0 auto"
            bg="linear-gradient(45deg, var(--slate-900),var(--slate-400), var(--slate-900))"
          ></Box>
          <Flex
            justifyContent="flex-end"
            width="calc(min(800px, 96%))"
            paddingTop="24px"
          >
            <Text
              fontSize={['16px', '18px', '20px']}
              fontWeight="400"
              color="var(--slate-300)"
              letterSpacing="0.02em"
            >
              {/* We are a zero-code data platform for the future, enabling you to
              build better solutions faster. With the ease and pace that our
              end-to-end platform provides, you can focus time and effort on
              designing better, newer solutions rather than on resolving data
              quality, and infrastructure issues. */}
              Our zero-code data science platform is designed to solve real
              world challenges faced by data scientists and enterprises. Focus
              your time and effort on creating better, newer solutions rather
              than on resolving data quality and infrastructure issues with the
              ease and efficiency of our end-to-end platform.
              {/* platform to deliver innovative solutions. With the ease and pace
              that our end-to-end platform provides, you can focus time and
              effort on designing better, newer solutions rather than on
              resolving data quality, and infrastructure issues. Save 80% of the
              time spent on data engineering and boilerplate stuff while
              delivering a robust, reusable workflow. */}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default HomeSection1;
